@import "vars.scss";

#content p {
    padding: 10px 0 0 0;
}

#content .tagline {
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.1rem;
    text-align: center;
    border-top: $border-vars-light;
    border-bottom: $border-vars-light;
    margin: 20px 40px 20px 40px;
    padding: 10px;
}

.thumbnail-container {
    padding: 0 20px 0 0px;
}

#content .thumbnail-container {
    float: left;
    max-width: 300px;
    text-align: center;
}

.thumbnail {
    border-radius: $border-radius;
    border: $border-vars;
    width: 250px;
    margin: 20px 0 20px 0;
}

.thumbnail-container .img-text {
    font-style: italic;
    font-weight: 200;
    width: 250px;
    text-align: center;
}

.blog {
    margin: 20px 5px 20px 5px;
}

.date {
    display: inline-block;
    border-radius: $border-radius;
    border: $border-vars;
    padding: 5px 10px 5px 10px;
}

.month {
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
}

.year {
    font-size: 0.8rem;
    text-align: center;
}

.heading {
    font-size: 2em;
    font-weight: 600;
    margin: -5px 0 -10px 0;
}

.menu-icon {
    margin: 0 10px 0 0;
}