@import "scss/content.scss";
// @import "scss/sidebar.scss";
// @import "scss/menu.scss";
// @import "scss/media.scss";
// @import "scss/timeline.scss";

// custom styling goes here
.App {
    color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body { 
  
  background: linear-gradient(247deg, #150a69, #423c6b, #661a13);
  background-size: 600% 600%;

  -webkit-animation: BGAnim 40s ease infinite;
  -moz-animation: BGAnim 40s ease infinite;
  animation: BGAnim 40s ease infinite;
  font-family: var(--font-family);
}

.shrink-on-scroll {
  
  transition: 0.2s;
}

@-webkit-keyframes BGAnim {
  0%{background-position:1% 0%}
  50%{background-position:99% 100%}
  100%{background-position:1% 0%}
}
@-moz-keyframes BGAnim {
  0%{background-position:1% 0%}
  50%{background-position:99% 100%}
  100%{background-position:1% 0%}
}
@keyframes BGAnim {
  0%{background-position:1% 0%}
  50%{background-position:99% 100%}
  100%{background-position:1% 0%}
}

